import {
  avatarField,
  badgeField, birthdayField, cityField,
  dateOfEntryField, educationField,
  emailField, facebookField, factsAboutMeField,
  firstNameField,
  instagramField, interestsField,
  lastNameField,
  passwordField, passwordFieldRequired,
  phoneField,
  positionTags,
  roleField, sexField,
  statusField, telegramField, wasteTypesField,
} from 'features/residents/layouts/general';
import { translate } from 'magner';

export const cloverLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      /** Lastname and Firstname */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [lastNameField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [firstNameField],
            }],
          },
        ],
      },
      /** Phone, Email */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [emailField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [phoneField],
            }],
          },
        ],
      },
      /** Status */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [statusField],
      },
      /** Password, role */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [{
                type: 'input',
                name: 'roleCurrent',
                props: {
                  disabled: true,
                  hidden: true,
                },
              },
              {
                type: 'input',
                name: 'rolesList',
                props: {
                  disabled: true,
                  hidden: true,
                },
              }, roleField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [passwordField,
                passwordFieldRequired],
            }],
          },
        ],
      },
      /** Avatar */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          /** Avatar */
          {
            type: 'column',
            props: {
              span: 8,
              xs: 24,
            },
            fields: [avatarField],
          },
          /** Entry */
          {
            type: 'column',
            props: {
              span: 16,
              xs: 24,
              styles: { 'padding-left': '12px' },
            },
            layout: [
              badgeField,
              dateOfEntryField,
            ],
          },
        ],
      },
      /** Club position (role) and atlases amount */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [positionTags],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      /** ABOUT ME */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_me'),
        props: { span: 24, isPaper: true, titleType: 'heading' },
        layout: [
          /** City and Birth date */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [cityField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [birthdayField],
                }],
              },
            ],
          },
          /** Gender identity */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [sexField],
          },
          /** Education */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [educationField],
          },
          /** Facts about me */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [factsAboutMeField],
          },
          /** Interests (hobbies) */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [interestsField],
          },
          /** Waste Types */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [wasteTypesField],
          },

          /** CONTACTS */
          /** Telegram and VK */
          {
            type: 'row',
            props: { titleType: 'heading', elementsGrow: true },
            title: translate('hubstr.resident.form.contacts.label'),
            fields: [telegramField],
          },
          /** Facebook and Instagram */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [facebookField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [instagramField],
                }],
              },
            ],
          },
        ],
      },
    ],
  },
];
