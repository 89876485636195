import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import {
  Department, Resident, ResidentCreate, ResidentList, RolesResponse, Tag, UserRoles, UserStatus,
} from 'features/residents/types/hubstr';
import hubstrConfig from 'features/residents/layouts/meta-hubstr';
import { request } from '~/utils/request';
import { getRolesIdName } from '~/utils/get-roles';

const BASE_URL = '/api/admin/user';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

const createNestedKeys = ['userBadge', 'userExpertise', 'userProvideResources', 'userAchievements', 'userYearTarget', 'userLookResources',
  'userRequest', 'userIndustries', 'userBusinessStartYear', 'userTurnoverPerYear', 'userNumberOfEmployees', 'userFactsAboutMe',
  'userInterests', 'userEducation', 'userFamilyStatus', 'userChildren', 'userVideoPresentation', 'userPersonalWww', 'userFormGroup', 'userAtlas',
  'description', 'userCity', 'userLookExpertise', 'userWasteTypes', 'userSports'];

export const residentRead = request.table<ResidentList>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  // TODO: вернуть сортировку
  // const sort = '&sort[0][id]=lastName&sort[0][value]=ASC';
  const sort = '';
  const search = data.filters.firstName
    ? `&filters[0][id]=search&filters[0][value]=${data.filters.firstName}`
    : '';
  const phone = data.filters.phone
    ? `&filters[1][id]=phone&filters[1][value]=${data.filters.phone}`
    : '';
  const communityManager = data.filters.communityManager
    ? `&filters[2][id]=communityManager&filters[2][value]=${data.filters.communityManager}`
    : '';
  const buddyPartner = data.filters.mentor
    ? `&filters[2][id]=mentor&filters[2][value]=${data.filters.mentor}`
    : '';
  const positionTags = data.filters.positionTags
    ? `&filters[3][id]=positionTag&filters[3][value]=${data.filters.positionTags}`
    : '';

  let status = '';
  if (data.filters.status?.length) {
    const arr = data.filters.status.map((id: string) => `filters[4][value][]=${id}`) as string[];
    status = `&filters[4][id]=status&${arr.join('&')}`;
  }

  const communityManagerName = data.filters.communityManagerName
    ? `&filters[5][id]=communityManagerName&filters[5][value]=${data.filters.communityManagerName}`
    : '';

  const res = await api.get<{ list: ResidentList[], pager: Pager }>(
    `${BASE_URL}/list${pagination}${sort}${search}${phone}${communityManager}${positionTags}${status}${buddyPartner}${communityManagerName}`,
  );
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((item) => ({
        ...item,
        fio: `${item.firstName} ${item.lastName}`,
      })),
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

/**
 * Метод получает список всех ролей
 */
export const autocompleteRole = request.card<{rows: RolesResponse[]}>(async ({ api }) => {
  const res = await api.get<{ data: RolesResponse[] }>('/api/admin/role/list');
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data.data },
  };
});

export const residentSearch = request.custom<{ rows: Resident[] }, string>(async ({ api, data }) => {
  const search = data?.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';
  const res = await api.get<{ list: Resident[] }>(
    `${BASE_URL}/list?page=1&sortBy=firstName&sortWay=asc${search}`,
  );
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data.list.map((item) => ({ ...item, name: `${item.lastName} ${item.firstName}` })) },
  };
});

export const autocompleteSearch = (type: 'city'|'interests'|'industries'|'userPosition'|'department'
  |'wasteTypes'|'expertise'|'communityTypes'|'communityPosition') => request
  .custom<{ rows: string[] }, string>(async ({ api, data }) => {
    const search = data?.search ? `&search=${data.search}` : '';
    const res = await api.get<{ result: string[] }>(`/api/form/autocomplete?field=${type}${search}`);
    if (res.error) {
      return {
        data: { rows: [] },
      };
    }

    return {
      data: { rows: res.data?.result },
    };
  });

export const autocompleteDepartment = request.custom<{ rows: Department[] }, string>(async ({ api, data }) => {
  const res = await api.get<{ departments: Department[] }>('/api/department/autocomplete');

  // удалить пункт "по-умолчанию"
  const departments = res.data?.departments.filter((i) => i.id !== '68ff3374-3ac4-4393-87e9-b874865fd0fb');

  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: departments },
  };
});

export const residentGet = request.card<Resident, ResidentCreate>(async ({ api, data, parseError }) => {
  const rolesList = await getRolesIdName();
  const res = await api.get<{ user: Resident }>(`${BASE_URL}/read?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      password: '',
      ...res.data?.user,
      roleCurrent: res.data?.user.roles?.[0],
      status: (res.data?.user.status as unknown as UserStatus).code,
      birthday: res.data?.user.birthday ? new Date(res.data?.user.birthday) as any : null,
      dateOfEntry: res.data?.user.dateOfEntry ? new Date(res.data?.user.dateOfEntry) as any : null,
      roles: res.data?.user?.roles?.[0].id as any,
      ...res.data?.user.additions,
      userCity: res.data?.user.additions.userCity?.split(', ') || null,
      userInterests: res.data?.user.additions.userInterests || [],
      userIndustries: res.data?.user.additions.userIndustries || [],
      userLookExpertise: res.data?.user.additions.userLookExpertise || [],
      positionTags: res.data?.user.positionTags?.map((tag) => (tag as unknown as Tag).id) as string[],
      recommendationUser: res.data?.user.recommendationUser?.id ?? null,
      description: res.data?.user.additions.description ?? '',
      videoPresentation: res.data?.user.additions.video_presentation ?? '',
      rolesList,
    },
  };
});

// @ts-ignore
export const residentNew = request.card<Resident, ResidentCreate>(async ({ api, data, parseError }) => {
  const autocompleteRoles = await autocompleteRole('');
  const rolesList = await getRolesIdName();

  console.log('sd');

  return {
    data: {
      roles: autocompleteRoles.data?.rows.find((r) => r.name === UserRoles.Resident)?.id,
      rolesList,
    },
  };
});

export const residentUpdateStatus = request.card(async ({
  api, data, parseError,
}) => {
  const body = { id: data.id, status: data.data.status };
  const res = await api.patch(`${BASE_URL}/update/status`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: 'ok' };
});

export const residentCreate = request.card<Resident, ResidentCreate>(async ({
  api, parseError, data, router,
}) => {
  const id = uuidv4();

  const body: any = { id, additions: {} };
  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.userCity) body.additions.userCity = data.data.userCity.join(', ');
  if (data.data.userInterests) body.additions.interests = data.data.userInterests;
  if (data.data.userIndustries) body.additions.userIndustries = data.data.userIndustries;
  if (data.data.userAchievements) body.additions.userAchievements = data.data.userAchievements;
  if (data.data.positionTags === '') body.positionTags = [];
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;
  if (!data.data.recommendationUser || data.data.recommendationUser === '') body.recommendationUser = null;

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.post<{ user: Resident }>(`${BASE_URL}/create`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident', params: { id: res.data?.user.id } });
  return { data: res.data?.user };
});

export const residentUpdate = request.card<Resident, ResidentCreate>(async ({ api, parseError, data }) => {
  const body: any = { id: data.id, additions: {} };
  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.userCity) body.additions.userCity = data.data.userCity.join(', ');
  if (data.data.userInterests) body.additions.userInterests = data.data.userInterests;
  if (data.data.userIndustries) body.additions.userIndustries = data.data.userIndustries;
  if (data.data.userAchievements) body.additions.userAchievements = data.data.userAchievements;
  if (data.data.positionTags === '') body.positionTags = [];
  if (data.data.userNumberOfEmployees === '') body.additions.userNumberOfEmployees = null;
  if (data.data.userTurnoverPerYear === '') body.additions.userTurnoverPerYear = null;
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;
  if (!data.data.recommendationUser || data.data.recommendationUser === '') body.recommendationUser = null;

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.patch<{ user: Resident }>(`${BASE_URL}/update`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  // Если обновился статус
  if (data.data.status) {
    await residentUpdateStatus(data);
  }

  return { data: res.data?.user };
});

export const residentDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`${BASE_URL}/delete?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'residents' });
  return { data: 'ok' };
});

/**
 * Метод получает список всех статусов для резидента
 */
export const residentStatusList = request.card<{rows: UserStatus[]}>(async ({ api }) => {
  const res = await api.get<{ statuses: UserStatus[] }>(`${BASE_URL}/statuses`);
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data?.statuses },
  };
});
