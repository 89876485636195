<template>
  <div class="phone-header">
    <el-row>
      <el-col :span="8">
        <el-avatar
          :size="70"
          :src="avatar"
        />
      </el-col>
      <el-col :span="16">
        <InputFlat
          :label="translateText('hubstr.editor.phone_preview.edit_content.name.label')"
          :placeholder="translateText('ruCustom.hubstr.editor.phone_preview.edit_content.name.placeholder')"
          required
        />
      </el-col>
    </el-row>
  </div>

  <div class="phone-body">
    <InputFlat
      :label="translateText('hubstr.editor.phone_preview.edit_content.surname.label')"
      required
    />
    <InputFlat
      v-if="badge"
      :label="translateText('hubstr.editor.phone_preview.edit_content.badge.label')"
    />

    <br>
    <div
      v-for="view in state.body"
      :key="view"
      class="phone-body__group"
    >
      <h3
        v-if="view.adminGroupType !== ADMIN_GROUP_TYPES.COMMUNITIES && view.adminGroupType !== ADMIN_GROUP_TYPES.SOCIAL"
        class="phone-body__title"
      >
        {{ TranslatesList[view.title.replace('lang.', '')] || view.title }}
      </h3>
      <div
        v-for="field in view.widgets"
        :key="field.title"
        class="phone-body__field"
      >
        <component
          :is="TYPE_FIELDS_TO_COMPONENTS[field.type]"
          :key="field.title"
          :data="field"
        />
      </div>
    </div>
  </div>
  <br>
  <br>
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive,
} from 'vue';
import {
  Header, Widgets, Body,
} from 'features/settings/mobile-editor/interfaces';
import InputFlat from 'features/settings/mobile-editor/components/right-side/components/input-flat.vue';
import ListLine
  from 'features/settings/mobile-editor/components/right-side/components/fields/phone-edit-content/list-line.vue';
import TextSection
  from 'features/settings/mobile-editor/components/right-side/components/fields/phone-edit-content/text-section.vue';
import InfoLineAction
  from 'features/settings/mobile-editor/components/right-side/components/fields/phone-edit-content/info-line-action.vue';
import ListLineRawAction
  from 'features/settings/mobile-editor/components/right-side/components/fields/phone-edit-content/list-line-raw-action.vue';
import { ADMIN_GROUP_TYPES, TYPE_FIELDS_TO_COMPONENTS } from 'features/settings/mobile-editor/constants';
import { translate, useTranslate } from 'magner';
import { TranslatesList } from '../../../requests';

export default defineComponent({
  name: 'PhoneEditContent',
  components: {
    InputFlat, ListLine, TextSection, InfoLineAction, ListLineRawAction,
  },
  props: {
    data: {
      type: Object as PropType<Widgets>,
      required: true,
    },
  },
  setup (props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data))) as Widgets;

    const avatar = props.data.header.find((i: Header) => i.type === 'header_avatar_widget')?.value || '';
    const badge = props.data.header.find((i: Header) => i.type === 'header_badge_widget')?.value;

    /**
     * Добавляем к контактам соц. сети
     * */
    const socials = state.body.find((group: Body) => group.adminGroupType === ADMIN_GROUP_TYPES.SOCIAL)?.widgets;
    const contacts = state.body.find((group: Body) => group.adminGroupType === ADMIN_GROUP_TYPES.CONTACTS)?.widgets;
    if (socials?.length) {
      socials.forEach((s) => {
        if (!contacts?.map((c) => c.name).includes(s.name)) {
          contacts?.push({
            leftText: s.name,
            type: 'list_line_widget',
          } as never);
        }
      });
    }

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      state,
      badge,
      avatar,
      translateText,
      TranslatesList,

      TYPE_FIELDS_TO_COMPONENTS,
      ADMIN_GROUP_TYPES,
    };
  },
});
</script>

<style lang="scss" scoped>
.phone-header {
  margin-bottom: 20px;
}

.phone-body {
  &__title {
    margin-bottom: 20px;
  }
  &__group {
    margin-bottom: 30px;
  }
}
</style>
