import type { EventGuest } from 'features/events/guests/types/hubstr';
import type { EventReceive } from 'features/events/types/hubstr';
import { request } from '~/utils/request';
import { routeTransform } from '~/utils/route-transform';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const eventGuestsRead = request.table<EventGuest>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  const sort = '&sortBy=firstName&sortWay=asc';

  const eventId = window.location.pathname.replace(`${routeTransform()}events/`, '').replace('/guests', '');
  const event = `&filters[0][id]=event&filters[0][value]=${eventId}`;

  const search = data.filters.firstName ? `&filters[1][id]=search&filters[1][value]=${data.filters.firstName}` : '';
  const status = data.filters.status ? `&filters[2][id]=status&filters[2][value]=${data.filters.status}` : '';

  const [resGuests, resEvent] = await Promise.all([
    api.get<{ list: EventGuest[], pager: Pager }>(
      `/api/admin/event/invite/list${pagination}${sort}${event}${search}${status}`,
    ),
    api.get<{ event: EventReceive }>(`/api/admin/event?event=${eventId}`),
  ]);
  if (resGuests.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }
  // const headerEl = document.querySelector('.page .magner-page-header_title');
  // if (headerEl && resEvent.data) {
  //   headerEl.textContent = `${customT(translate('hubstr.event.guests.sidebar.card_title'))} "${resEvent.data.event.name}"`;
  // }

  return {
    data: {
      rows: resGuests.data.list,
      pagination: {
        currentPage: resGuests.data.pager?.currentPage || 1,
        totalPages: resGuests.data.pager?.totalPages || 1,
        totalItems: resGuests.data.pager?.totalItems || 0,
      },
    },
  };
});
