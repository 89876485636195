import { ClubRole } from 'features/settings/club-in-club/types/hubstr';
import { request } from '~/utils/request';

export const clubRoleRead = request.table<ClubRole>(async ({ api }) => {
  const res = await api.get<{ list: ClubRole[] }>('/api/admin/user/position/list');
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: null,
    },
  };
});

export const clubRoleGet = request.card<ClubRole, ClubRole>(async ({ api, data, parseError }) => {
  const res = await api.get<{ tag: ClubRole }>(`/api/admin/user/position/get?tag=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.tag };
});

export const clubRoleCreate = request.card<ClubRole, ClubRole>(async ({
  api, parseError, data, router, 
}) => {
  const res = await api.post<{ tag: ClubRole }>('/api/admin/user/position/create', data.data);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  window.location.reload();
  return { data: res.data?.tag };
});

export const clubRoleUpdate = request.card<ClubRole, ClubRole>(async ({ api, parseError, data }) => {
  const body = { ...data.data, tag: data.id };
  const res = await api.patch<{ tag: ClubRole }>('/api/admin/user/position/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  return { data: res.data?.tag };
});

export const clubRoleDelete = request.card(async ({ api, data, parseError }) => {
  const res = await api.delete(`/api/admin/user/position/delete?tag=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
