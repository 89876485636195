/**
 * Приводит текст типа - "мои хобби" в "MoiHobbi"
 * @param word
 * @param format
 * */
const translit = (word: string, format: 'pascal' | 'camel') => {
  const converter = {
    а: 'a', б: 'b', в: 'v', г: 'g', д: 'd',
    е: 'e', ё: 'e', ж: 'zh', з: 'z', и: 'i',
    й: 'y', к: 'k', л: 'l', м: 'm', н: 'n',
    о: 'o', п: 'p', р: 'r', с: 's', т: 't',
    у: 'u', ф: 'f', х: 'h', ц: 'c', ч: 'ch',
    ш: 'sh', щ: 'sch', ь: '', ы: 'y', ъ: '',
    э: 'e', ю: 'yu', я: 'ya',
  } as any;

  word = word.toLowerCase();

  let answer = '';

  for (let i = 0; i < word.length; ++i) {
    if (converter[word[i]] === undefined) {
      answer += word[i];
    } else {
      answer += converter[word[i]];
    }
  }

  const camelCase = answer.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

  switch (format) {
    case 'camel':
      return camelCase; // camelCase
    case 'pascal':
      return camelCase.charAt(0).toUpperCase() + camelCase.slice(1); // PascalCase
    default:
      return camelCase;
  }
};

export default translit;
