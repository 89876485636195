import { tableController, translate } from 'magner';
import {
  emailField, firstNameField, lastNameField, phoneField, statusField, telegramField,
} from 'features/events/guests/table/general';
import { EventGuest } from 'features/events/guests/types/hubstr';

const eventGuestsTable = tableController<EventGuest>({
  emptyText: translate('hubstr.event.guests.table.not_found'),
  rowLink: (row) => ({ name: 'resident', params: { id: row.id } }),
  columns: [
    firstNameField,
    lastNameField,
    emailField,
    phoneField,
    telegramField,
    statusField,
  ],
});

export default eventGuestsTable;
