import { request } from '~/utils/request';

const logoutRequest = request.custom(async ({
  router, lstorage, api, parseError, 
}) => {
  const res = await api.delete('/api/auth/logout');

  if (res.error) {
    return { error: parseError(res.error) };
  }

  api.instance.defaults({ headers: {} }, false);
  lstorage.delete('token');
  
  return {
    data: null,
  };
});

export default logoutRequest;
