import { ADMIN_GROUP_TYPES, GROUP_TYPES } from 'features/settings/mobile-editor/constants';

export const defaultProfile = {
  id: '',
  title: 'Профиль пользователя',
  code: 'user_profile',
  widgets: {
    body: [
      {
        name: null,
        type: GROUP_TYPES.GROUP,
        title: 'lang.business_information',
        adminGroupType: ADMIN_GROUP_TYPES.GROUP,
        widgets: [
          {
            widgetCode: 'userBusinessStartYear',
            name: null,
            type: 'list_line_widget',
            leftIcon: 'suitcase',
            leftText: 'lang.business_start_year',
            rightIcon: null,
            rightText: 'data.additions.userBusinessStartYear',
          },
          {
            widgetCode: 'userTurnoverPerYear',
            name: null,
            type: 'list_line_widget',
            leftIcon: 'pie_chart',
            leftText: 'lang.turnover_per_year',
            rightIcon: null,
            rightText: 'data.additions.userTurnoverPerYear',
          },
          {
            widgetCode: 'userNumberOfEmployees',
            name: null,
            type: 'list_line_widget',
            leftIcon: 'peoples',
            leftText: 'lang.number_of_employees',
            rightIcon: null,
            rightText: 'data.additions.userNumberOfEmployees',
          },
        ],
      },
      {
        name: null,
        type: GROUP_TYPES.GROUP,
        title: 'lang.industries',
        adminGroupType: ADMIN_GROUP_TYPES.GROUP,
        widgets: [
          {
            widgetCode: 'userIndustries',
            type: 'list_line_raw_action_widget',
            leftIcon: 'folder_full',
            leftText: 'lang.userIndustries',
            rightIcon: null,
            rightText: 'data.additions.userIndustries',
            hasAutoComplete: true,
          },
        ],
      },
      {
        type: GROUP_TYPES.COMMUNITIES,
        title: 'lang.company',
        adminGroupType: ADMIN_GROUP_TYPES.COMMUNITIES,
        widgets: [
          {
            data: 'data.companies',
            type: 'company_list_widget',
            widgets: [],
          },
        ],
      },
      {
        name: null,
        type: GROUP_TYPES.GROUP,
        title: 'lang.how_can_i_help',
        adminGroupType: ADMIN_GROUP_TYPES.GROUP,
        widgets: [
          {
            widgetCode: 'userProvideResources',
            name: null,
            text: 'data.additions.userProvideResources',
            type: 'text_section_widget',
            title: 'lang.provide_resources',
          },
        ],
      },
      {
        name: null,
        type: GROUP_TYPES.GROUP,
        title: 'lang.about_me',
        adminGroupType: ADMIN_GROUP_TYPES.GROUP,
        widgets: [
          {
            widgetCode: 'userBadge',
            name: null,
            text: 'data.additions.userBadge',
            type: 'text_section_widget',
            title: 'lang.badge',
          },
          {
            widgetCode: 'userFactsAboutMe',
            name: null,
            text: 'data.additions.userFactsAboutMe',
            type: 'text_section_widget',
            title: 'lang.facts_about_me',
          },
          {
            widgetCode: 'userInterests',
            name: null,
            type: 'text_section_widget',
            title: 'lang.interests',
            text: 'data.additions.userInterests',
            values: 'data.additions.userInterests',
            hasAutoComplete: true,
          },
          {
            widgetCode: 'userEducation',
            name: null,
            text: 'data.additions.userEducation',
            type: 'text_section_widget',
            title: 'lang.education',
          },
          {
            widgetCode: 'userChildren',
            name: null,
            text: 'data.additions.userChildren',
            type: 'text_section_widget',
            title: 'lang.children',
          },
          // {
          //   name: null,
          //   type: 'array_section_widget',
          //   title: 'lang.city',
          //   values: 'data.additions.userCity',
          //   hasAutoComplete: true,
          // },
          {
            widgetCode: 'userCity',
            name: null,
            type: 'text_section_widget',
            title: 'lang.city',
            values: 'data.additions.userCity',
            text: 'data.additions.userCity',
            dataProvider: null,
            hasAutoComplete: true,
          },
          {
            widgetCode: 'birthday',
            name: 'date',
            value: 'data.birthday',
            type: 'text_section_widget',
            title: 'lang.birthday',
            dataProvider: null,
          },
        ],
      },
      {
        name: null,
        type: GROUP_TYPES.GROUP,
        title: 'lang.contacts',
        adminGroupType: ADMIN_GROUP_TYPES.CONTACTS,
        widgets: [
          {
            widgetCode: 'phone',
            name: 'phone',
            text: 'data.contacts.phone',
            type: 'info_line_action_widget',
            title: 'lang.phone',
            isShow: 'data.hidePhone',
            infoText: 'lang.phone_visibility',
            hasPrivacy: true,
          },
          {
            widgetCode: 'telegram',
            name: 'telegram',
            type: 'list_line_widget',
            leftIcon: null,
            leftText: 'lang.telegram',
            rightIcon: 'next',
            rightText: 'data.contacts.telegram',
          },
        ],
      },
      // {
      //   type: GROUP_TYPES.SOCIAL,
      //   title: 'lang.socials',
      //   adminGroupType: ADMIN_GROUP_TYPES.SOCIAL,
      //   widgets: [
      //     {
      //       link: 'data.contacts.telegram',
      //       name: 'telegram',
      //       type: 'social_button_widget',
      //     },
      //     {
      //       link: 'data.contacts.vk',
      //       name: 'vk',
      //       type: 'social_button_widget',
      //     },
      //     {
      //       link: 'data.contacts.facebook',
      //       name: 'facebook',
      //       type: 'social_button_widget',
      //     },
      //     {
      //       link: 'data.contacts.instagram',
      //       name: 'instagram',
      //       type: 'social_button_widget',
      //     },
      //   ],
      // },
    ],
    footer: [
      {
        name: 'date',
        text: 'data.entry',
        type: 'entry_widget',
      },
      {
        text: 'data.additions.userFormGroup',
        type: 'form_group_widget',
        empty: 'lang.looking_form_group',
      },
    ],
    header: [
      {
        type: 'header_avatar_widget',
        value: 'data.avatar',
      },
      {
        type: 'header_badge_widget',
        value: 'data.additions.userBadge',
      },
      {
        type: 'header_title_widget',
        value: 'data.fullName',
      },
      {
        name: 'icons',
        type: 'header_group_buttons_widget',
        title: null,
        widgets: [
          {
            icon: 'thank_you_heart',
            link: 'data.thanks',
            name: 'thanks',
            type: 'header_social_button_widget',
            enabled: 'data.thanksEnabled',
          },
          {
            icon: null,
            link: 'data.contacts.phone',
            name: 'phone',
            type: 'header_social_button_widget',
            enabled: 'data.phoneEnabled',
          },
          {
            icon: null,
            link: 'data.contacts.telegram',
            name: 'telegram',
            type: 'header_social_button_widget',
            enabled: 'data.telegramEnabled',
          },
        ],
      },
    ],
    actions: [],
    data: {
      id: 'data.id',
      name: 'data.name',
    },
  },
};
