import { tableController, translate } from 'magner';
import type { Company } from 'features/residents/companies/types/hubstr';
import { routeTransform } from '~/utils/route-transform';

const companyTable = tableController<Company>({
  emptyText: translate('hubstr.resident.companies.table.not_found'),
  rowLink: (row) => {
    const residentId = window.location.pathname.replace(`${routeTransform()}residents/`, '').replace(/\/companies.*/, '');
    return { name: 'company', params: { id: row.id }, query: { resident: residentId } };
  },
  columns: [
    {
      prop: 'logo',
      label: translate('hubstr.resident.companies.table.logo'),
      view: {
        // @ts-ignore
        type: 'image',
        // @ts-ignore
        nestedKey: 'big',
      },
    },
    {
      prop: 'name',
      label: translate('hubstr.resident.companies.table.name'),
      width: 200,
    },
    {
      prop: 'areas',
      label: translate('hubstr.resident.companies.table.areas'),
    },
    {
      prop: 'userPosition',
      label: translate('hubstr.resident.companies.table.user_position'),
    },
  ],
});

export default companyTable;
