<template>
  <el-button
    v-if="modelValue"
    type="text"
    @click="clickButton(modelValue)"
  >
    Перейти в опросник
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { Organizer } from 'features/events/types/hubstr';
import { routeTransform } from '~/utils/route-transform';

export default defineComponent({
  name: 'PollLink',
  props: {
    field: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array as PropType<any>,
      required: true,
    },
  },
  setup () {
    // @ts-ignore
    const clickButton = (poll) => {
      window.open(`${routeTransform()}/settings/interviews/${poll.id}`, '_blank');
    };

    return {
      clickButton,
    };
  },
});
</script>
