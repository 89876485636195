<template>
  <div v-if="data.widgets.length">
    <el-row
      v-for="item in data.widgets"
      :key="item"
      align="top"
      class="company-list"
    >
      <el-col :span="4">
        <el-avatar :size="40">
          <img
            :src="item.logo"
          >
        </el-avatar>
      </el-col>
      <el-col :span="18" class="company-list__right">
        <div class="company-list__title">{{ item.title || '-' }}</div>
        <div class="company-list__text">{{ item.text || '-' }}</div>
      </el-col>
      <el-col :span="2">
        <div class="company-list__arrow">
          <ArrowRight />
        </div>
      </el-col>
      <el-divider />
    </el-row>
  </div>

  <div v-else>
    <el-row
      v-for="item in 2"
      :key="item"
      align="top"
      class="company-list"
    >
      <el-col :span="4">
        <el-avatar :size="40">
          <el-skeleton-item variant="circle" />
        </el-avatar>
      </el-col>
      <el-col :span="17" class="company-list__right">
        <el-skeleton :rows="1" />
      </el-col>
      <el-col :span="3">
        <div class="company-list__arrow">
          <ArrowRight />
        </div>
      </el-col>
      <el-divider />
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ArrowRight } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'CompanyList',
  components: { ArrowRight },
  props: {
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.company-list {
  margin-bottom: 0;
  &__right {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: 15px;
    height: 100%;
    width: 20px;
    color: var(--el-text-color-secondary);
  }
  &__title {
    font-size: 14px;
    font-weight: 500;
  }
  &__text {
    color: var(--el-text-color-secondary);
  }
}
.el-skeleton__paragraph {
  margin-top: 5px !important;
}
</style>
